module.exports = {
  siteTitle: "Andrew Li", // <title>
  shortSiteTitle: "Andrew - post for fun", // <title> ending for posts and pages
  siteDescription: "Andrew Li from Long Island New York Jericho Senior High school",
  siteUrl: "https://andrewli.pro",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Andrew Li",
  authorTwitterAccount: "AndrewL44687003",

  /* info */
  headerTitle: "Andrew Li",
  headerSubTitle: ": )",

  /* manifest.json */
  // REMINDER - THIS IS WHERE THE FACE IMAGE AND : ) ARE DISPLAYED
  manifestName: "Andrew Li - have fun!",
  manifestShortName: "Andrew", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/ANDREW0307" },

  ]
};
